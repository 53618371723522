//@ts-nocheck
import React, { useState, useEffect } from 'react';
import MishipayBackground from '../../assets/new-bg.svg';
import MishipayLogo from '../../assets/mishipay-logo.png';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Hidden,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
  LinearProgress,
  InputAdornment,
  IconButton,
  Input,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-query';
import { signin, storesApi, registerFCMTokenApi } from 'src/services/user';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import { computeStores, getRequiredStores } from 'src/utils';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext } from 'react';
import { LanguageContext } from 'src/components/LanguageProvider';
import { Link, useHistory } from 'react-router-dom';
import { startCase, uniqBy } from 'lodash';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { v4 as uuidv4 } from 'uuid';
import { datadogLogs } from '@datadog/browser-logs';

const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    width: '100vw',
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${MishipayBackground})`,
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'contain',
  },
  logoContainer: {
    display: 'flex',
    flex: 0.6,
    alignItems: 'center',
  },
  logo: {
    objectFit: 'contain',
    height: '60px',
  },
  innerContainer: {
    display: 'flex',
    flex: 0.4,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  username: {
    width: '100%',
    maxWidth: '343px',
  },

  password: {
    width: '100%',
    maxWidth: '343px',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
  },

  buttonSignUp: {
    flex: 1,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },

  buttonLogin: {
    flex: 1,
    marginLeft: 16,
    height: '40px',
    color: 'white',
    fontWeight: 'bold',
  },

  forgotPassword: {
    color: theme.palette.color_dark_text.main,
  },

  multiSelect: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    position: 'absolute',
    height: '48px',
    bottom: 50,
  },
  innerContainer2: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '300px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    top: 20,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 343,
    },
  },
};

const menuItems = [
  {
    title: 'English',
    value: 'en',
  },
  {
    title: 'Spanish',
    value: 'es',
  },
  {
    title: 'French',
    value: 'fr',
  },
  {
    title: 'Norwegian',
    value: 'no',
  },
];

const Login = () => {
  const context = useContext(LanguageContext);
  const [inputs, setInputs] = useState({ username: '', password: '' });
  const [passwordVisibilty, togglePassword] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [cookies, setCookies] = useCookies();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const fcmToken = sessionStorage.getItem('fcm');

  const handleInputChange = (e: any) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };
  const registerFCMToken = (payload: any) => {
    return registerFCMTokenApi.create(
      { v1: 'v_d2', token: cookies.app_token },
      JSON.stringify(payload),
    );
  };
  const loginUser = (payload: any) => {
    return signin.create({}, JSON.stringify(payload));
  };
  const handleLogin = () => {
    if (inputs.username.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyUsername' }));
    } else if (inputs.password.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyPassword' }));
    } else {
      mutate(inputs);
    }
  };

  const handleClickShowPassword = () => {
    togglePassword(!passwordVisibilty);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);
  const lovesNamingConvention = (stores: any = selectAllStores) => {
    if (stores.length > 50) {
      return stores;
    }
    stores?.filter((store: any) => {
      if (store?.store_type === 'LovesStoreType') {
        store = editLovesStoreName(store);
      }
    });
    return stores;
  };

  const editLovesStoreName = (store: any) => {
    if (store.address_json?.length) {
      let address = JSON.parse(store.address_json);
      let clonedObject = { ...address };
      clonedObject = {
        ...clonedObject,
        retailer: `${store.retailer_store_id} - ${address.city}, ${address.state}`,
      };
      // store.retailer = `${store.retailer_store_id} - ${address.city}, ${address.state}`;
      return clonedObject;
    }
    return store;
  };
  const {
    isLoading: FCMLoading,
    mutate: FCMMutate,
    reset: FCMReset,
  } = useMutation(registerFCMToken, {
    onError: (serverError: any) => {
      datadogLogs.logger.error('registerFCMToken ErrorHandler', serverError);
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: async successData => {
      console.log('Success Data', successData);
      // setSuccess(true);
    },
  });
  const { isLoading, mutate, reset } = useMutation(loginUser, {
    onError: (error: any) => {
      setError(true);
      if (error.indexOf(' password') != -1) {
        setErrorMessage(
          `${intl.formatMessage({ id: 'invalidPassword' })} ${error
            .split(' ')
            .slice(4, error.length)
            .join(' ')}`,
        );
      } else if (error.indexOf('username') != -1) {
        setErrorMessage(
          `${intl.formatMessage({ id: 'invalidUsername' })} ${error
            .split(' ')
            .slice(2, error.length)
            .join(' ')}`,
        );
      } else {
        setErrorMessage(error);
      }
      setStoreLoading(false);
    },

    onSuccess: async response => {
      if (get(response, 'data.success', false)) {
        let currentURL = window.location.href.split('//')[1].split('/')[0];
        if (currentURL.includes(':')) {
          currentURL = currentURL.split(':')[0];
        }
        const {
          access_token,
          user_id = '',
          username = '',
          email,
          can_refund = false,
          can_edit = false,
          can_add = false,
          can_delete = false,
          allow_analytics = false,
          allow_settlement_report = false,
          retailer = '',
          stores = [],
          store_id,
          ...rest
        } = get(response, 'data.result', {});

        const userPayload = {
          userData: {
            user_id,
            username,
            email,
          },
          permissions: {
            can_refund,
            can_edit,
            can_add,
            can_delete,
            allow_analytics,
            allow_settlements: allow_settlement_report,
          },
        };
        let includeDemoAndInactiveStores =
          process.env.REACT_APP_ENV_TYPE !== 'Production';

        setStoreLoading(true);
        const storesResponse = await storesApi.query({
          v1: true,
          token: access_token,
          show_demo: true,
        });

        let storesData = get(storesResponse, 'data.data', []);
        storesData = lovesNamingConvention(storesData);

        // let modifyStoresData = computeStores(storesData);
        let modifyStoresData = storesData.filter((item: any) => {
          return !item.retailer.split(' ').includes('All');
        });

        let defaultStore = modifyStoresData.find(
          (store: any) => store.store_id === store_id,
        );

        let active_store = defaultStore?.demo
          ? modifyStoresData.find((store: any) => !store?.demo)
          : modifyStoresData.find((store: any) => store.store_id === store_id);

        let regions = uniqBy(
          modifyStoresData
            .map((item: any) => {
              if (item.region) {
                return {
                  title: item.region,
                  value: item.region,
                };
              }
            })
            .filter((item: any) => item && item.value),
          'value',
        );
        let retailers = uniqBy(
          modifyStoresData
            .map((item: any) => {
              if (item.store_type) {
                return {
                  title: startCase(item.store_type.replace('StoreType', '')),
                  value: item.store_type,
                };
              }
            })
            .filter((item: any) => item && item.value),
          'value',
        );

        let activeStorePayload = active_store
          ? active_store
          : modifyStoresData[0];

        let defaultRegion = [
          {
            value: activeStorePayload?.region,
            title: activeStorePayload?.region,
            type: 'single',
          },
        ];
        let defaultRetailers = [
          {
            title: startCase(
              activeStorePayload?.store_type.replace('StoreType', ''),
            ),
            value: activeStorePayload?.store_type,
            type: 'single',
          },
        ];
        dispatch({
          type: 'SET_FCM_TOKEN',
          payload: fcmToken,
        });
        dispatch({
          type: 'UPDATE_USER_DATA',
          payload: userPayload,
        });
        dispatch({
          type: 'SET_CURRENT_STORE_DATA',
          payload: activeStorePayload,
        });
        dispatch({
          type: 'SET_STORES',
          payload: modifyStoresData,
        });
        dispatch({
          type: 'SET_REGIONS',
          payload: regions,
        });
        dispatch({
          type: 'SET_RETAILERS',
          payload: retailers.sort((a, b) => {
            a = a.title.toLowerCase();
            b = b.title.toLowerCase();
            return a < b ? -1 : a > b ? 1 : 0;
          }),
        });
        dispatch({
          type: 'SET_ACTIVE_STORES',
          payload: getRequiredStores(
            modifyStoresData,
            includeDemoAndInactiveStores,
            includeDemoAndInactiveStores,
          ),
        });
        dispatch({ type: 'SET_SELECTED_REGIONS', payload: [] });
        dispatch({ type: 'SET_SELECTED_RETAILERS', payload: [] });
        dispatch({
          type: 'INCLUDE_DEMO_STORES',
          payload: includeDemoAndInactiveStores,
        });
        dispatch({
          type: 'INCLUDE_INACTIVE_STORES',
          payload: includeDemoAndInactiveStores,
        });
        dispatch({
          type: 'LOGIN_USER',
        });
        dispatch({
          type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
          payload: {
            title: startCase(
              modifyStoresData[0].store_type.replace('StoreType', ''),
            ),
            value: modifyStoresData[0].store_type,
          },
        });
        console.log({ modifyStoresData });

        dispatch({
          type: 'CHANGE_PAGE_NUMBER',
          payload: {
            ccOrders: 1,
            orders: 1,
            kiosk: 1,
          },
        });
        await setCookies('app_token', access_token, {
          path: '/',
          domain: currentURL,
        });
        if (fcmToken) {
          FCMMutate({
            device_id: parseInt(uuidv4(), 16),
            registration_id: fcmToken,
            source: 'FCM',
          });
        }
        setStoreLoading(false);
      } else {
        setError(true);
        setErrorMessage('Error in login');
        setStoreLoading(false);
      }
    },
  });

  return (
    <Paper className={classes.paperContainer}>
      <Hidden only={['xs']} implementation="css">
        {(isLoading || storeLoading) && <LinearProgress color="primary" />}
      </Hidden>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box component="div" className={classes.logoContainer}>
            <img
              src={MishipayLogo}
              alt="Mishipay Logo"
              className={classes.logo}
            />
          </Box>
          <Box className={classes.innerContainer2}>
            <Typography>
              <FormattedMessage
                id="welcome"
                defaultMessage="Welcome to the new MishiPay Dashboard"
              />
            </Typography>

            <Input
              className={classes.username}
              name="username"
              id="username"
              placeholder={intl.formatMessage({ id: 'username' })}
              onChange={handleInputChange}
            />

            <Input
              className={classes.password}
              name="password"
              id="password"
              placeholder={intl.formatMessage({ id: 'password' })}
              onChange={handleInputChange}
              type={passwordVisibilty ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordVisibilty ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.buttonSignUp}
                variant="outlined"
                color="primary"
                disabled={isLoading || storeLoading}
                onClick={() => history.push('/signup')}
              >
                <FormattedMessage id="signUp" defaultMessage="SIGN UP" />
              </Button>
              <Button
                className={classes.buttonLogin}
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={isLoading || storeLoading}
              >
                <FormattedMessage id="login" defaultMessage="LOGIN" />
              </Button>
            </Box>
            <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
              <Typography className={classes.forgotPassword}>
                {`${intl.formatMessage({ id: 'forgotPassword' })}?`}
              </Typography>
            </Link>
          </Box>
          <Select
            className={classes.multiSelect}
            value={context.locale}
            onChange={e => {
              context.changeLanguage(e.target.value);
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {menuItems.map((item: any) => (
              <MenuItem key={item.title} value={item.value}>
                <ListItemText primary={item.title} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        {(isLoading || storeLoading) && (
          <Box className={classes.mobileLoaderContainer}>
            <Box className={classes.mobileLoader}>
              <CircularProgress
                className={classes.circle}
                size={25}
                thickness={5}
              ></CircularProgress>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        )}
      </Hidden>
    </Paper>
  );
};

export default Login;
